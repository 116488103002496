@use '../../../Styles/ColorsAndFonts.scss' as *;
.MainMenuShade {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: $primary-light-grey;
  opacity:0;
  animation: .2s ease greyOutFadeIn;
  user-select: none;
  z-index: -10001;

  &.isShowing {
    opacity: 0.3;
    user-select: all;
    z-index: 10001;

  }
}
.MainMenu  {
  position: fixed;
  bottom: -300px;
  left: 30px;
  z-index: 10002;
  border-radius: 20px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, .15);
  padding: 10px;
  height: 220px;
  // width: 1060px;
  // transition: all 0.3s;
  opacity: 0;
  transition-timing-function: ease-out;
  transition: 0.25s;

}

.MainMenu.isShowing {
  transform: translateY(-400px);
  opacity: 1;

  transition-timing-function: ease-in;
  transition: 0.2s;

}

.MainMenu {
  .mainNavButton {
    z-index: 10003;
    float: left;
    width: 200px;
    height: 200px;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 12px;
    transition: 0.25s background-color;
    position: relative;
    margin-left: 10px;
    cursor: pointer;

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70px;
      height: 70px;
      cursor: pointer;

    }
    .title {
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
      font-size: 20px;
      
      color: $text-light;
      cursor: pointer;

    }
  }
  .mainNavButton:hover {
        background-color: $grey-5;
        .title { color: $text-light;   }
  }
  .mainNavButton:first-child {
    margin-left: 0;
  }

}




@keyframes rotateToX {
  100% {
    -webkit-transform: rotate(45deg) translateZ(0);
    transform: rotate(45deg) translateZ(0);
    background-color: #fff;
  }
}

.navButton {
  cursor: pointer;
  position: absolute;
  bottom: 30px;
  left: 20px;
  z-index: 19995;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  box-shadow: 0 4px 12px rgba(65, 59, 56, 0.15);
}


.navButton.closeNav {
  background-color: #fff;
}

.navButton.openNav {
  background-color: #E92200;
}

.redStick, .yellowStick {
  width: 26px;
  height: 3px;
  border-radius: 36px;
  z-index: 10003;
  left: 18px;
  position: absolute;
}

.navButton.openNav .redStick {
  bottom: 28px;
  transition: all .2s ease;
  -webkit-transform: rotate(45deg) translateZ(0);
  transform: rotate(45deg) translateZ(0);
  background-color: #fff;
}

.navButton.openNav .yellowStick {
  bottom: 28px;
  transition: all .2s ease;
  -webkit-transform: rotate(-45deg) translateZ(0);
  transform: rotate(-45deg) translateZ(0);
  background-color: #fff;
}



.mainNavToggleButton .navButton.closeNav .yellowStick {
  background-color: #FFA900;
  bottom: 22px;
  transition: all .2s ease;
  -webkit-transform: rotate(0deg) translateZ(0);
  transform: rotate(0deg) translateZ(0);
}



.mainNavToggleButton .navButton.closeNav .redStick {
  background-color: #E92200;
  bottom: 32px;
  transition: all .2s ease;
  -webkit-transform: rotate(0deg) translateZ(0);
  transform: rotate(0deg) translateZ(0);
}


.navButton.closeNav:hover {
  background-color:#FFF5E2;
}

.navButton.openNav:active { //when x is clicked
  animation: .5s ease rotateXCounter;
}

.navButton.closeNav:active { //when burger is clicked
  animation: .2s ease rotateHooverClockwise;
}
