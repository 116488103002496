$red:    #EC5A41;
$red-light: #FFEAE6;
$red-text: #DAA49B;

$orange: #F67902;
$yellow: #FFA900;
$yellow-light: #FFF5E2;

$purple: #CA00B0;

$teal:   #00CABE;
$teal-light: #E1F6F5;

$white: #FFFFFF;

$text:  #413B38;
$text-light:  #5E5450;


$primary-dark-grey:  #413B38;
$primary-light-grey: #5E5450;

$grey-0: #A8A4A2;
$grey-1: #C3BCB9;
$grey-2: #DAD3D0;
$grey-3: #E9E3DF;
$grey-4: #F0ECE9;
$grey-5: #F5F1EF;
$bright: #FDF6F3;
$poppy:  #FFF5E2;


$family-primary:'Source Sans Pro', sans-serif;
$family-secondary:'Poppins', sans-serif;


%font-1,
%title-font {
    font-family: $family-secondary;
    font-weight: 700; 
}
%font-2 ,
%label-font {
    font-family: $family-primary;
    font-weight: 600; 
}
%font-3,
%text-font {
    font-family: $family-primary;
    font-weight: 400;
}

%unselectable { 
    pointer-events: none; 
    user-select: none; 
}
 
%noselect {
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
  }
   .noselect {
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
   }

  
  
