.botUserConversation {
 .swivl-mainFont {
   font-family: 'Poppins', sans-serif;;
 }

 .swivl-secondaryFont {
   font-family: 'Poppins', sans-serif;;
 }

 .swivl-header {
   background: #E92200;
   color: #FFFFFF;
 }

 .swivl-loadPastMessages        {
   color: #DAD3D0;
  }
 .swivl-loadPastMessages:hover  {
   color: #C3BCB9;
 }
 .swivl-loadPastMessages:active {
   color: #A8A4A2;
 }

 .swivl-loadingPastMessagesSpinner {
   fill: #00CABE;
 }

 .swivl-loadingPastMessages {
   color: #00CABE;
 }

 .swivl-body {
   background: rgba(255, 255,255,1);
 }

 .swivl-gradientLeft {
   background: #FFFFFF;
  background: linear-gradient(90deg, rgba(255, 255,255,1) 0%, rgba(255, 255,255,0) 100%);
 }

 .swivl-gradientRight {
   background: #FFFFFF;
  background: linear-gradient(90deg, rgba(255, 255,255,0) 0%, rgba(255, 255,255,1) 100%);
 }

 .swivl-userBubble {
   background: #FFF;
   color: #5E5450;
 }

 .swivl-botBubble {
   background: #5E5450;
   color: #fff;
 }
 .scc-leftSide .swivl-linkContainer, .scc-leftSide .swivl-linkContainer a {
    color: #fff;
    text-decoration: underline;
 }
.scc-leftSide .scc-typingIndicator span {
  color: #fff;
  background-color: #fff;
 }
 .swivl-timestamp {
   color: #949494;
 }

 .swivl-form {
   background: #FFFFFF;
   color: #000000;
 }

 .swivl-mainBackground {
   background: #FFFFFF;
 }

 .swivl-sendButton {
   background: #E92200;
   color: #FFFFFF;
 }
 .swivl-sendButton:hover {
   background: #F67902;
   color: #FFFFFF;
 }
 .swivl-sendButton:active {
   background: #F67902;
   color: #FFFFFF;
 }

 .swivl-primaryButton {
   background: #E92200;
   color: #FFFFFF;
 }
 .swivl-primaryButton:hover {
   background: #F67902;
   color: #FFFFFF;
 }
 .swivl-primaryButton:active {
   background: #F67902;
   color: #FFFFFF;
 }

 .swivl-svgButton svg        {
   fill: #E92200;
  }
 .swivl-svgButton:hover svg  {
   fill: #F67902;
 }
 .swivl-svgButton:active svg {
   fill: #F67902;
 }

 .swivl-secondaryButton {
   background: #E92200;
   color: #FFFFFF;
 }
 .swivl-secondaryButton:hover {
   background: #F67902;
   color: #FFFFFF;
 }
 .swivl-secondaryButton:active {
   background: #F67902;
   color: #FFFFFF;
 }

 .swivl-tertiaryButton {
   background: #E92200;
   color: #FFFFFF;
 }
 .swivl-tertiaryButton:hover {
   background: #F67902;
   color: #FFFFFF;
 }
 .swivl-tertiaryButton:active {
   background: #F67902;
   color: #FFFFFF;
 }
}
