@use '../../../Styles/ColorsAndFonts.scss' as *;
.SaveInput {
  margin-bottom: 10px;
  color: $text-light;
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
  .leftTitle {
    text-transform: uppercase;
    margin-bottom: 2px;
    font-family: $family-primary;
    color: $text-light;
    font-size: 13px;
    float: left;
    clear: both;
  }
  .rightTitle {
    text-transform: uppercase;

    margin-bottom: 2px;
    color: $text-light;
    font-family: $family-primary;

    font-size: 13px;
    float: right;
  }
  .inputBlock {
    width: 100%;
    float: left;
    padding: 10px;
    background: $grey-4;
    border-radius: 6px;
    margin-bottom: 10px;
  }
  .inputWrapper {
    position: relative;
    border: 1px solid $grey-2;
    width: 100%;
    padding: 8px 10px 8px 10px;
    border-radius: 4px;
    background: $white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease; 

  }
  .inputWrapper:hover {
    border: 1px solid   $yellow;

  }
  input {
    flex:1;
    font-size: 15px;
    font-family: $family-primary;


    width: 100%;
    height: 30px;
    line-height: 30px;
    padding: 0;
    margin: 0;
    outline: none;
    border: none;
    &:focus {
      outline: none;
      border: none;
    }
  }

  .saveButton {
    display: none;
    width: 50px;
    border-radius: 3px;
    background: $grey-0;
    color: $white;
    height: 24px;
    line-height: 24px;
    text-align: center;
    cursor: pointer;
    padding: 0;
    position: relative;
    .title, .spinner {
      font-weight: bold;
      color: $white;
      font-size: 12px;
      position:absolute; 
      align-items: center;
      justify-content: center;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

    }



  }
  .saveButton:hover {
    background: $orange;
  }
  .cancelButton {
    font-weight: bold;
    display: none;
    width: 50px;
    border-radius: 3px;
    background: $white;
    height: 24px;
    line-height: 24px;
    text-align: center;
    cursor: pointer;
    color: $text-light;
    font-size: 12px;

  }
  .cancelButton:hover {
    color: $orange;
  }

}
.SaveInput.editing .saveButton,
.SaveInput:hover .saveButton {
  display: block
}
.SaveInput.editing {
  .cancelButton {
    display: block;
  }
  .inputWrapper {
    border: 1px solid $yellow;
  }
  .saveButton {
    background: $yellow;
  }
  .saveButton:hover {
    background: $orange;
  }
}




.SaveInput .spinner {
  display: none;
  align-items: center;
  justify-content: center;
}
.SaveInput.loading .spinner {
  display: flex;
}
.SaveInput.loading .saveButton .title {
  color: rgba(0,0,0,0);
}

.SaveInput.white {
  .inputBlock {
    padding: 0;
    background: $white;
    border-radius: 0;
    
  }
}
.SaveInput.greyOutline {
  .inputBlock { background: none; padding:0; }
  .inputWrapper {
    background: $white;
    border-color: $yellow;
  }
}
