@use "../../../../../Styles/ColorsAndFonts.scss" as *; 

.ssTitleSwitch {
  width: auto;
  height: 47px;
  background: $grey-5;
  border-radius: 24px;
  position: relative;
  font-size: 10px;
  cursor: pointer;
  transition: .5s ease;

.ssTitleSwitchItemsWrapper  {
  z-index: 10;

  display: flex;
  flex-direction: row;
  width: auto;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  font-weight: bold;
}
.ssTitleSwitchItem {
  height: 47px;
  line-height: 47px;
  white-space: nowrap;
  font-size: 18px;
  color: $text-light;
  text-align: center;
  transition: .2s ease;
  z-index: 100;
  user-select: none;

}
.ssTitleSwitchItem.selected,
.ssTitleSwitchItem:hover {
  color: $orange;
}

.ssTitleSwitchIndicatorWrapper {
  position: absolute;
  z-index: 0;
}
.ssTitleSwitchIndicator {
  z-index: 0;
  position: absolute;
  top:3px;
  height: 41px;
  border-radius: 21px;
  background: #FFF;
  box-shadow: 0  2px 4px 0 rgba(0,0,0,0.15);
  transition: .3s ease;
}
}