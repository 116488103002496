@use "../../Styles/ColorsAndFonts.scss" as *; 

.Toastify__toast {
    background: #FFF6E2;
    color: $orange; 
    &svg, 
    &button {
        color: $orange; 
        fill:$orange; 
    }
    box-shadow: none;
}

.Toastify__toast svg {

    color: $orange; 
    fill:$orange; 
}
