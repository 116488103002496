@use '../../Styles/ColorsAndFonts.scss' as *;


.SCButton {
  cursor: pointer;
  position: relative;
  border:none; 
  font-weight: 600;
  border-radius: 3px;
  padding: 7px 14px;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family:'Source Sans Pro', sans-serif;
  font-weight: semibold;
  border-radius: 4px;

  &.isDisabled, 
  &:disabled {
    cursor: not-allowed;
  }

  &.isLoading {
    user-select: none;
    .btnText {
      opacity:0; 
    }
  }

  &.isFullwidth {
    width: 100%;
  }

  .spinner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.SCButton.sizeSmall {
  font-size: 12px;
  padding: 0 18px;
  height: 21px;
  &.isRound { border-radius: 11.5px; }
}

.SCButton.sizeMedium {
  padding: 0 20px;
  font-size: 13px;
  height: 30px;
  &.isRound { border-radius: 15px; }

}
.SCButton.sizeLarge {
  height: 44px;
  padding: 0 23px;
  font-size: 15px;
  &.isRound { border-radius: 22px; }
}


.SCButton.styleDefault {
  color: #413C38; 
  background:none;

  &.isSelected,
  &:hover {
    color:#F67902;
  }
  &:hover {
    color:#FFA904;
  }
  &.isDisable,
  &:disabled {
    color: #C3BCB9;
  }
}


.SCButton.styleOrange {
  background: #F77A04;
  color: #fff;

  &.isSelected,
  &:hover {
    background: #DB6A00
  }

  &:active {
    background: #F77A04;
  }

  &.isDisable,
  &:disabled {
    background: #E9E3DF;
    color: #C3BCB9;
  }
}

.SCButton.styleYellow {
  background: #FFA904;
  color: #fff;

  &.isSelected,
  &:hover {
    background: #DC9100;
  }

  &:active {
    background: #FFA904;
  }

  &.isDisable,
  &:disabled {
    background: #E9E3DF;
    color: #C3BCB9;
  }
}



.SCButton.styleTeal {
  background: #00CABE;
  color: #fff;

  &.isSelected,
  &:hover {
    background: #17B8AE;
  }

  &:active {
    background: #00CABE;
  }

  &.isDisable,
  &:disabled {
    background: #E9E3DF;
    color: #868a8a;
  }
}

.SCButton.styleLight {
  color: #413C38; 
  background:#F6F2EF;

  &.isSelected,
  &:active{
    background: #F6F2EF;
    color: #fff;
  }
  &:hover {
    color: #fff;

    background:#413C38;
  }
  &.isDisable,
  &:disabled {
    color: #C3BCB9;
  }
}
.SCButton.styleWhite {
  color: #413C38; 
  background:#FFF;

  &.isSelected,
  &:active{
    background: #F6F2EF;
    color: #fff;
  }
  &:hover {
    color: $orange;

    background:#FFF;
  }
  &.isDisable,
  &:disabled {
    color: #C3BCB9;
  }
}


.SCButton.styleDark {
  background: #5F5551;
  color: #fff;

  &.isSelected,
  &:hover {
    background: #413C38;
  }

  &:active {
    background: #5E5450;
  }

  &.isDisable,
  &:disabled {
    background: #E9E3DF;
    color: #C3BCB9;
  }
}



.SCButton.styleDashed {
  background: none;
  color: #5E5450;
  border: 2px dashed #C3BCB9;

  &.isSelected,
  &:hover {
    background: #FFF5E2;
    border-color: #FFA904;
    text-decoration: underline;
    color:#F67902; 
  }

  &:active {
    background: #FFF5E2;
    border-color: #F77A04;
    }

  &.isDisable,
  &:disabled {
    background: #E9E3DF;
    color: #C3BCB9;
    border-color: #C3BCB9;

  }
}
