@use "../../Styles/ColorsAndFonts" as *;

.ssSwitch {
  width: 50px;
  height: 22px;
  background: $grey-1;
  border-radius: 11px;
  position: relative;
  font-size: 10px;
  cursor: pointer;
  transition: .5s ease;
}
.ssSwitch .indicator {
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 10px;
  position: absolute;
  left: 1px;
  top:1px;
  z-index: 10;
  transition: .5s ease;
}
.ssSwitch .onLabel {
  position: absolute;
  left: 9px;
  top: 0;
  height: 22px;
  text-align: left;
  line-height: 22px;
  z-index: 1;
  transition: .3s ease;
  opacity: 0;
}
.ssSwitch .offLabel {
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 6px;
  text-align: right;
  line-height: 22px;
  color: $text;
  z-index: 1;
  transition: .3s ease;
  opacity: 1;
}
.ssSwitch:hover { background: $grey-0; }
.ssSwitch.on,
.ssSwitch.on:hover {
  background: $yellow;
}
.ssSwitch.on .indicator { left: 29px; }
.ssSwitch.on .onLabel  { opacity: 1; }
.ssSwitch.on .offLabel { opacity: 0; }
