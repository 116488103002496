@use '../../../../../Styles/ColorsAndFonts.scss' as * ;

.NameRow {
  width: 100%;
  float: left;
  padding-bottom: 20px;

  .name{
    float: left;
    font-size: 22px;
    font-weight: bold;
    line-height: 30px;

    color: $text-light;
  }
  .onlineIndicator {
    margin-left: 10px;
    display: inline-block;
    font-size: 14px;
    color: $grey-0;
    line-height: 30px;
    span {  color: $teal; font-size: 12px; line-height: 30px; }
  }
  .moreButton {
    float: right;
    position: relative;
    margin-top:8px;
    i:hover {
      color: $orange;
    }
  }
  .moreButton.showing i {
      color: $orange;
  }


  .moreDetails {
    position: absolute;
      right: 0;
      height: 68px;
      width: 203px;
      top:20px;
      border-radius: 8px;
      box-shadow: 0 3px 5px rgba(0,0,0,0.15);
      overflow: hidden;
      z-index: 9999;
  }
  .moreDetailsItem {
    float: left;
    width: 203px;
    height: 34px;
    line-height: 34px;
    cursor: pointer;
    position: relative;
    font-size: 14px;
    color: $text-light;
    padding: 0 0 0 10px;

    span {
      position: absolute;
      height: 34px;
      top:0;
      right: 0;
      display: none;
      line-height: 34px;
      padding: 0 10px;
      font-size: 12px;
      color: $orange;
      background: $poppy;

      @extend %label-font;
    }
  }
  .moreDetailsItem.copied span {
    color: $teal;
  }
    .moreDetailsItem:hover {
      background: $poppy;
      span {
        display: block;
      }
    }
}
