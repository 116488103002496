@use "./ColorsAndFonts" as *;

body, html {   
    background-color: #F5F1EF; 
    color:#413B38;
    @extend %text-font; 
    font-size: 14px;
}
*{ box-sizing: border-box;}

a {
    cursor: pointer;
    color: $orange;
    text-decoration: none;
}
  
  a:hover {
    color: $yellow;
    text-decoration: none;
  }
  label {
    @extend %label-font;
    color:$grey-0;  
    margin-bottom: 0.4em;
    display: inline-block;
  }

  .SCSpinner, .spin {
    animation: fa-spin 2s infinite linear;
  }
  @keyframes fa-spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
  
  


  input,
textarea {
  @extend %text-font;
  &:focus {
    outline: 1px solid $orange;
  }
}