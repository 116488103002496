@use '../../../Styles/ColorsAndFonts.scss' as * ;

.AgentSettingsView {
    .errorMsg {
        background: #ffe3e5;
        color: #eb5862;
        border: 1px solid #eb5862;
        padding: 10px;
        margin: 10px 0;
    }

    .steps {
        text-align: center;
    }


.justUpdatedView {
    background: $teal-light;
    color: $teal;
    border: 1px solid $teal;;
    padding: 10px;
    margin: 10px 0;
}
    .header {
        position: absolute;
        top:0;
        left: 0;
        right: 0;
        line-height: 70px;

        box-shadow:0 2px 4px 0 rgba(0,0,0,0.15);
        height: 70px;
        background-color: #fff;

        h1 { 
            z-index: 1;
            position: absolute;
            top:0;
            left: 0;
            right: 0;
            bottom: 0;
            text-align: center;
            line-height: 70px;
            color: $text
        }
        .backButton {
            cursor: pointer;
            position: absolute;
            top:0;
            left: 20px;
            bottom: 0;
            z-index: 1000;
        }
    }
    .content {
        position: absolute;
        top:70px;
        left: 0;
        right: 0;
        bottom: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .agentSettingsForm {
        margin: 20px 0 ;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        box-shadow:0 2px 4px 0 rgba(0,0,0,0.15);
        background-color: #fff;
        padding: 40px;
        border-radius: 12px;

        .avatar {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 200px;
            height: 200px;
            border-radius: 100px;
            background-color: $grey-2;
            margin-bottom: 20px;
            overflow: hidden;
        }

        .SCInput {
            width: 100%;
            margin: 10px 0 20px 0;
        }
    }
}