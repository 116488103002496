@use "../../../Styles/ColorsAndFonts.scss" as *;

.LoadingView {
  position: fixed;
  top:0;
  bottom:0;
  left:0;
  right:0;
  display: flex;
  align-items: center;
  justify-content: center;

.loadingContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.loadingLogo {
  margin-bottom: 20px;
   img{
  width: 101px;
  height: 44px;
}
}

.loadingBar {
  width: 200px;
  height: 20px;
  background-color: $orange;
  color: $white;
  font-weight: bold;
  text-align: center;
  line-height: 20px;
  font-size: 10px;
  border-radius: 10px;
  font-family: 'Source Sans Pro Semibold', sans-serif;

  background-image: repeating-linear-gradient(
      -45deg,
      transparent,
      transparent 1rem,
      $yellow 1rem,
      $yellow 2rem
    );
  background-size: 200% 200%;
  animation: barberpole 10s linear infinite;
}

  .hoverWave {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    overflow: hidden;
  animation: waver 0.65s alternate infinite;
  animation-timing-function: ease-in-out;
  margin-bottom: 10px;
  box-shadow: 0 0 2px 0 rgba(0,0,0,0.1);

}
  .hoverWave img{
    width: 100px;
    height: 100px;
  }

  @keyframes waver {
    0% {transform: rotate(-15deg);}
    100% {transform: rotate(20deg);}
  }
@keyframes barberpole {
  100% {
    background-position: 100% 100%;
  }
}

}
