@use "../../../../Styles/ColorsAndFonts.scss" as *;

.BotListItem ,.InviteListItem{
  height: 80px;
  width: 100%;
  position: relative;
  float: left;
  cursor: pointer;

  .avatar {
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    left: 20px;
    top: 15px;
    box-shadow: 0 0 2px 0 rgba(0,0,0,0.2);
    background: #fff;
    overflow: hidden;
  }
  
  .avatar img {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 0;
    top:0;
  }
  .name {
    position: absolute;
    height: 80px;
    left: 80px;
    top: 0;
    right: 0;
    bottom: 0;
    line-height: 80px;
    color: $text-light;
  }
  .spinner {
      position: absolute;
      width: 50px;
      height: 50px;
      border-radius: 25px;
      right: 5px;
      top: 15px;
      color: $grey-0;
      display: flex;
      align-items: center;
      justify-content: center;
  }
}
.BotListItem.selected, .BotListItem:hover {
    background: $poppy;
}
.BotListItem:active {
    background: $bright;
}
.BotListItem.disabled:hover {
  background: #fff;
}
.BotListItem.disabled {
  opacity: 0.6;
}

.InviteListItem{
  .actionButtons {
    position: absolute;
    height: 80px;
    left: 80px;
    top: 0;
    right: 40px;
    bottom: 0;
    line-height: 80px;
    color: $text-light;
    display: none;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
  }
  &:hover {
    .name {
      display: none;
    }
     .actionButtons {
      display: flex;
    }
  }

}