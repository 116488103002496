@use "../../Styles/ColorsAndFonts" as *;


.LoginView {
  position: absolute;
  top:0; right: 0; bottom: 0; left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  

}
.LoginContent {

    font-size: 14px;
  
    width: 500px;
    margin: auto;
    text-align: center;
    color: $primary-light-grey;
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
.swivlLoginPic {
  margin-bottom: 10px;
}
  .loginContent {
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .SCInput {
    width: 350px;
    input {
      text-align: center;
    }

  }

  .SCButton {
    margin-top: 20px;

    width: 350px;
    float: none;

  }
  


   h4 {
    font-size: 16px;
    margin:20px 0 5px; 
  }

  .loginText {
    margin-top: 20px;
    color: $grey-1;
  }

  .loginText:hover {
    cursor: pointer;
  }

  .forgotEmailInput {
    margin: 10px;
  }

  .backToLogin {
    margin-top: 15px;
  }

  .backToLogin:hover {
    cursor: pointer;
  }

  .forgotParagraph {
    margin-bottom: 15px;
  }

  .errorMsg {
    color: $red;
    font-size: 14px;
    margin-top: 5px;
  }
}
