@use '../../Styles/ColorsAndFonts.scss' as *;

.SCInput {
  position: relative;
  input {
    font-family:'Source Sans Pro', sans-serif;

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    outline: 1px solid #C3BCB9;
    border: none;
    color:#413B38;
    border-radius: 3px;

    &:hover, 
    &:focus { 
      outline: 1px solid #FFA904;
    }
    z-index: 1;

  }
  .icon {
    position: absolute;
    top: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    &.clickableIcon {
      cursor: pointer;
      opacity: 0.7;
      &:hover {
        opacity: 1;
      }
    }
    &.isLeft { left: 0; }
    &.isRight { right: 0; }
  }

  &.isDisabled, &:disabled {
   input {
    cursor: not-allowed;
    user-select: none;
    background-color: #F6F2EF;
    outline: 1px solid #F6F2EF;
    &:hover, 
    &:focus { 
      outline: 1px solid #F6F2EF;
    }
  }
  }
}


.SCInput.sizeSmall {
  font-size: 12px;
  height: 21px;
  margin-bottom: 12px;

  input {
    font-size: 12px;

    padding: 0 12px;
    height: 21px;

  }
  .icon { 
    height: 21px;
    width: 21px;
  }
  &.hasLeftIcon  input{  padding-left: 21px;  }
  &.hasRightIcon input{  padding-right: 21px;  }

  &.isRound input { border-radius: 11.5px; }
}

.SCInput.sizeMedium {
  font-size: 13px;
  height: 30px;
  margin-bottom: 13px;

  input {
    font-size: 13px;

    padding: 0 13px;
    height: 30px;
  }
  .icon { 
    height: 30px;
    width: 30px;
  }
  &.hasLeftIcon input {  padding-left: 30px;  }
  &.hasRightIcon input {  padding-right: 30px;  }
  &.isRound input { border-radius: 15px; }

}
.SCInput.sizeLarge {
  font-size: 15px;
  height: 44px;
  margin-bottom: 15px;


  input {
    padding: 0 15px;
    height: 44px;
    font-size: 15px;

  }
  .icon { 
    height: 44px;
    width: 44px;
  }
  &.hasLeftIcon input {  padding-left: 44px;  }
  &.hasRightIcon input {  padding-right: 44px;  }


  &.isRound input { border-radius: 22px; }
}
