@use "../../../Styles/ColorsAndFonts.scss" as *;


.SelectBotView {
  position: fixed;
  top:0;
  bottom:0;
  left:0;
  right:0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .content {
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(0,0,0,0.1);
    border-radius: 20px;
    width: 300px;
    overflow: hidden;
  }

  .header .logo {
    display: flex;
    flex-direction: column;
    align-items: center;
     img{
       width: 101px;
       height: 44px;
       margin-bottom: 10px;
      }
  }
  .header {
    padding: 20px 20px 0 20px;
    font-size: 16px;
    color: $text-light;
  }
  .subHeader {
    font-size: 12px;
    color: $grey-0;
    padding: 5px 20px;
  }
  .logout {
    color: $grey-0;
    font-size: 12px;
    font-family: 'Source Sans Pro Semibold', sans-serif;
    padding: 10px;
    font-weight: bold;
    cursor: pointer;
  }
  .logout:hover { color: $teal; }


}
