@use "../../../Styles/ColorsAndFonts.scss" as *; 

.NavBar {
    position: fixed;
    top:0; left:0; right:0;
    background-color: #fff;
    height: 70px;
    box-shadow: 0 1px 6px 0 rgba(0,0,0,0.15);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 0 20px;
    z-index: 9999;
    sup {
      font-style: italic;
      color:$orange; 
    }

    .leftNav, .centerNav, .rightNav {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      z-index: 2;

    }
    .centerNavSpacer {
      flex:1; 
    }
    .centerNav {
      z-index: 1;
      position: absolute;
      left:0;
      right:0;
      
    }
    .leftNav { 
      
      justify-content: flex-start;  
    .SCInput {
      margin-right: 10px;
      margin-top:10px;

      width: 200px;
    }
    
    }
    .centerNav {  margin: 0 20px;  }
    .rightNav { 
       justify-content: flex-end; 
       .textButton {
          margin-left: 15px;
       }

       .SCInput {
        margin-left: 10px;
        margin-top:10px;
  
        width: 200px;
      }
       }





.textButton {
  font-family: 'Source Sans Pro', sans-serif;

  position: relative;
  font-size: 14px !important;
  font-weight: bold;
  color: $grey-0;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  height: 20px;
  align-items: center;
  svg { margin-right: 5px;}
  background: transparent;
  border: none;
  outline: none;

  


  &.isLoading {
    .textButtonContent {
      opacity: 0;
    }
    .textButtonLoadingSpinner {
      opacity: 1;
      position: absolute;
      top:0;
      left:0;
      right:0;
      bottom:0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .textButtonLoadingSpinner {
    opacity: 0;
  }


}

.textButton:hover {
  color: $yellow;
  &.warning {
    color: $red;
  }
}

.title {
  padding-bottom: 2px;
  font-size: 18px;
  font-weight: bold;
  color: $text-light;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; 
  &.canEdit {
    cursor: pointer;
  }
  .titleEditButton {
    position: absolute; 
    right: -20px;
    top:1px;
    color: $grey-0;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }
  &:hover {
    .titleEditButton {
      opacity: 1;
    }
  }

  .SCInput {
    margin-left: 10px;
    margin-top:10px;
    width: 200px;

  }
  .SCButton {
    margin-left: 10px;
  }
}
  }
