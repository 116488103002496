@use '../../../Styles/ColorsAndFonts.scss' as * ;

.BotUserTranscriptDetails {
  padding: 20px 20px;

  .readOnlyField {
  
    

    span {
      border: 1px solid coral; 
      padding: 10px;
      border-radius: 4px;
    }
  }


  label {
    margin: 25px 0 5px 0;
  }
  .address {
    margin-bottom: 40px; 
  }
  .botUserVariables {
    display: flex;
    flex-direction: column;
    padding: 0 0 20px 0;
    .inputNoteWrapper, 
    .section {
      display: flex;
      flex-direction: column;
    }
  }


  .variableFieldsHeader {
    display: flex;
    flex-direction: row;
    font-weight: 600;
    font-size: 14px;
    margin:  20px 0 10px 0;
    cursor: pointer;
    color:$grey-0;
    &:hover {
      color:$text; 
    }
  }
}
.BotUserDetails  .userId {
  font-size: 12px;
  color: $grey-1;
}
.BotUserDetails .tabs{
  padding: 20px 0;
}

.BotUserDetails .tabs .tab{
  font-size: 18px;
  font-weight: bold;
  display: inline-block;
  margin-right: 20px;
  cursor: pointer;
  user-select: none;
  color: $text-light;
}
.BotUserDetails .tabs .tab:hover {
  color: $orange;
}
.BotUserDetails .tabs .tab.selected {
  color: $orange;
  text-decoration: underline;
}
.BotUserDetails .BotUserDetailsRow {
  color: $text-light;
  margin-bottom: 20px;
  font-size: 16px;
}



.hubspotButton {
  display: block;
  height: 40px;
  border-radius: 20px;
  text-align: center;
  line-height: 40px;
  background: #F4F0EF;
  color: $text-light;
  margin: 0 0 20px;

  i { line-height: 40px;}

}
.hubspotButton.active {
  cursor: pointer;
}
.hubspotButton.syncing,
.hubspotButton.active:hover {
  background: #FFF4E1;
}
.hubspotButton.syncing i,
.hubspotButton.active i {
  margin-right: 10px;
}
.hubspotButton.active i {
    color:#ea7c3a;
}
.hubspotButton.synced i {
    color:$teal;
}
.BotUserDetails {
  .sectionHeading {
    width: 100%;
    float: left;
    font-size: 16px;
    color:$orange;
    margin: 10px 0;
    .text {
      width: 100%;
      float: left;
      font-size: 14px;
      color: $text-light;
      margin: 10px 0;

    }
  }
  .inputNote {

    float: left;
    font-size: 12px;
    margin: -15px 0 12px 0px;
    padding: 0 8px;
    color: $grey-0;
  }
  .SSSaveInput {
    .leftTitle, .rightTitle {
      color:$grey-0;
      text-transform: uppercase;
      padding: 0 9px;
      font-size: 14px;
    }
    .inputBlock  {
      padding: 0 0 10px 0;
      background: transparent;
    }
    .inputWrapper {
      position: relative;
      border: 1px solid #F0ECE9;
      width: 100%;
      height: 48px;
      padding: 8px 60px 8px 10px;
      border-radius: 4px;
      background: #FFFFFF;
    }
    .cancelButton { top: 11px; right: 68px; }
    .saveButton   { top: 11px; right: 10px; }
  }
}
