@use "../../../Styles/ColorsAndFonts.scss" as *;

.RefreshModal {
    width: 544px;
    padding: 20px 40px;



    h1 {opacity: 1;
        color: rgba(94,84,80,1);

        font-size: 18px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0px;
        text-align: center;
        line-height: 25px;
        margin: 0;

    }
    h2 {
        opacity: 1;
color: rgba(94,84,80,1);
@extend %title-font;

font-size: 18px;
font-weight: 600;
font-style: normal;
letter-spacing: 0px;
text-align: center;
line-height: 25px;
margin: 0;
    }
    h3 {
        opacity: 1;
color: rgba(94,84,80,1);
@extend %font-2;
font-size: 18px;
font-weight: 400;
font-style: normal;
letter-spacing: 0px;
text-align: center;
line-height: 25px;
margin: 0 0 40px 0;

    }

    .buttons {
        display: flex;
        flex-direction: row;
        align-items: center;

        justify-content: center;
        button {
            margin: 0 5px;
        }
    }
}