@use "../../Styles/ColorsAndFonts" as *;

.SSImageUpload {
    width: 100%;
    height: 100%;
  position: relative;
  cursor: pointer;
  background: $grey-4;

  color: $grey-0;
  overflow: hidden;
  transition: all 0.3s ease;
  font-size: 18px;
}
.uploadAnAvatar {
  width: 100%;
  height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

}
.SSImageUpload .SSImageUploadContent {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

}
.SSImageUpload.SSImageUploadHasFile {
  background: #fff;
}

.SSImageUpload:hover  {
  box-shadow: rgba(0,0,0,0.2) 0 0 4px 0;
 }
 .SSImageUpload img {
   position: absolute;
   width: 100%;
   height: 100%;
  object-fit: cover;
  }

.SSImageUploadIcons {
  transition: all 0.3s ease;
  opacity: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px;
  background: rgba(0,0,0,0.5);
  border-radius:6px;
  z-index: 100;
}
.SSImageUploadIcon {
  transition: all 0.3s ease;
  margin: 5px;
  color: #fff;
  cursor: pointer;
}
.SSImageUploadIcons a {
  color: #fff;
}
.SSImageUploadIcons a,
.SSImageUploadIcon:hover {
  color: $teal;
}

.SSImageUpload:hover .SSImageUploadIcons {
  opacity: 1;
}
