
@use '../../Styles/ColorsAndFonts.scss' as * ;
.TranscriptView {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

.transcriptError {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .icon {
        font-size: 24px;      
        margin-bottom: 5px;

    }
    .title { 
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 0;
    }
    .message {
        font-size: 15px;
        margin-bottom: 20px;
        font-style:italic;
        
    }
}

.botUser {
    background: $grey-5;
    padding: 20px;
}

    

    .chatRow {
        position: absolute;
        bottom: 145px;
        top: 0;
        left: 0;
        right: 0;
        transition: all 0.3s ease-in-out;
        .swivl-body {
            // background: none !important;
        }
        .swivlChat {
            background: $grey-5;
            border-radius: 0;
            border: none;
        }

    }
    .chatForm {
        position: absolute;
        bottom:0;
        left: 0;
        right:0;
        height: 145px;
        background: $grey-5;
        border-top: 1px solid $grey-2;
        padding: 40px 10px;
        align-items: center;
        display: flex;
        justify-content: center;
        font-style: italic;
        text-align: center;
    }

}

.TranscriptRenewButton {
    color: $grey-1;

    min-width: 150px;
    display: flex;
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
    text-transform: uppercase;
    .renewButton {
        display: none;
    }
    &:hover {
        .renewButton {
            display: block;
            color: $orange;
        }
        .expiresText {
            display: none;
        }
    }
}