@use "../../Styles/ColorsAndFonts.scss" as *;

.pageContent {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
  overflow-x: hidden;
}
.navBarPageContent {
  top: 70px;
}

.Page {
  padding: 40px 40px 120px 40px;
}
.PageThreeColumn {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;

  .column {
    box-sizing: border-box;
    float: left;
    width: calc(100% / 3);
    height: 100%;
    background: $grey-5;
    overflow: hidden;
    position: relative;
    
    &:first-child {
      border-right: 1px solid $grey-2;
      background: #fff;
    }
    &:last-child {
      border-left: 1px solid $grey-2;
      background: #fff;
      overflow-y: scroll;
    }
  }
}


.PageWithSidebarMenu {
  position: fixed; 
  top: 70px;
  left: 0;
  right: 0;
  bottom: 0;
  .pageWithSidebarMenuContent {
    position: absolute;
    top: 0;
    left: 230px;
    right: 0;
    bottom: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: #fff;
  }

  .sideBarMenu {
    position: absolute;
    text-decoration: none;
    top: 10px;
    font-family: 'Source Sans Pro', sans-serif;
    width: 230px;
    height: 100%;
    background: $grey-5;
    z-index: 3;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 110px;

    .menuItem {
      border-radius: 8px;
      display: block;
      padding: 15px;
      margin: 5px 10px;
      font-weight: bold;
      color: $text;
      &:hover,
      &.active {
        background: $grey-3;
        text-decoration: none;
        color: $text;
      }
    
    }
    

  }
}